@import url("https://rsms.me/inter/inter.css");

/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  font-family: 'Inter', sans-serif;
}

@supports (font-variation-settings: normal) {
  :root {
    font-family: 'Inter var', sans-serif;
  }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.editable-test {
  border: 2px solid transparent;
}

.editable-test:hover {
  background-color: var(--bs-gray-200);
  border-radius: 5px;
}

.editable-test-focused {
  background-color: var(--bs-gray-200);
  border-radius: 5px;
  border: 2px solid var(--bs-primary);
  box-sizing: border-box;
}

/* Remove caret from datalist input */
[list]::-webkit-calendar-picker-indicator {
  opacity: 0;
}